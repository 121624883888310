import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAlert, hideAlert } from '../redux/alertSlice'; // Ensure the correct path

const AlertComponent = () => {
  const dispatch = useDispatch();
  const { message, action } = useSelector(selectAlert);

  const handleClose = () => {
    if (action) {
      dispatch(action); // Dispatch the action when the dialog is closed
    }
    dispatch(hideAlert()); // Close the alert
  };

  return (
    <>
      {message && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Dialog Box */}
          <div className="card w-full max-w-md bg-base-100 shadow-xl z-50">
            <div className="card-body">
              <h2 className="card-title text-lg font-semibold">Alert</h2>
              <p>{message}</p>
              <div className="card-actions justify-end">
                <button
                  onClick={handleClose}
                  className="btn btn-primary"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertComponent;
