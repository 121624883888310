import { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useDeleteClientMutation,
  useSearchClientsWithPaginationQuery,
} from "../../redux/clientApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import dayjs from "dayjs";
import useMediaQuery from "../../hooks/useMediaQuery"; // Ensure you have a custom hook for media queries
import RouteConfig from "../../config/RouteConfig";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";
import Table from "../../components/Table";
import { FaDollarSign, FaEdit, FaTrashAlt } from "react-icons/fa";
import ConfirmDialog from "../../components/ConfirmDialog";
import useSnackbar from "../../hooks/useSnackbar";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ClientListScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const isMobile = useMediaQuery("(max-width: 600px)"); // Example breakpoint for mobile view
  const { gymId } = useSelector(selectGym);

  const order = query.get("sortOrder") || "asc";
  const orderBy = query.get("sortBy") || "name";
  const page = parseInt(query.get("page") || 0, 10);
  const rowsPerPage = parseInt(query.get("rowsPerPage") || 25, 10);

  const name = query.get("name") || "";
  const phone = query.get("phone") || "";

  const {
    data: clientsData,
    isLoading,
    isError,
    error,
  } = useSearchClientsWithPaginationQuery({
    gymId: gymId,
    name: name,
    phone: phone,
    pageNo: page,
    pageSize: rowsPerPage,
    sortBy: orderBy,
    sortOrder: order,
  });

  const handleRequestSort = (property) => {
    const columnMapping = {
      Name: "name",
      Phone: "phone",
      Membership: "membershipEndDate",
      Image: "image",
    };

    const isAsc = orderBy === columnMapping[property] && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    updateURL({ sortOrder: newOrder, sortBy: columnMapping[property] });
  };

  const handleChangePage = (newPage) => {
    updateURL({ page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    updateURL({ rowsPerPage: newRowsPerPage, page: 0 });
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    updateURL({ [name]: value, page: 0 });
  };

  const updateURL = (params) => {
    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams);

    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        searchParams.set(key, params[key]);
      }
    });
    // window.location.search = searchParams.toString();
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const calculateDays = (expiryDate) => {
    const today = dayjs();
    const expiry = dayjs(expiryDate);
    const difference = expiry.diff(today, "day");
    return difference;
  };

  return (
    <div className=" mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Clients</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => navigate(`${RouteConfig.CLIENTS}/add`)}
        >
          Add New Client
        </button>
      </div>

      <div className="mb-4">
        <p className="text-lg font-semibold">Search Clients By:</p>
      </div>

      <SearchForm name={name} phone={phone} onChange={handleSearchChange} />

      <FetchWrapper
        isError={isError}
        error={error}
        isLoading={isLoading}
        isEmptyList={clientsData && clientsData.content.length === 0}
      >
        {clientsData && (
          <>
            {isMobile ? (
              <ClientCardList
                clients={clientsData.content}
                navigate={navigate}
              />
            ) : (
              <ClientTable
                clients={clientsData.content}
                orderBy={orderBy}
                order={order}
                onSort={handleRequestSort}
                navigate={navigate}
                calculateDays={calculateDays}
              />
            )}
            <PaginationControls
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={clientsData.paginationResponse.totalElements}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </FetchWrapper>
    </div>
  );
};

const SearchForm = ({ name, phone, onChange }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
    <input
      type="text"
      name="name"
      value={name}
      onChange={onChange}
      placeholder="Name"
      className="w-full p-2 border rounded"
    />
    <input
      type="text"
      name="phone"
      value={phone}
      onChange={onChange}
      placeholder="Phone"
      className="w-full p-2 border rounded"
    />
  </div>
);

const ActionsComponent = ({ data }) => {
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deleteClient] = useDeleteClientMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleDeleteClient = async () => {
    try {
      await deleteClient(data.id).unwrap();
      showSnackbar("Deleted client successfully!", "success");
    } catch (error) {
      showSnackbar(error.data.message);
      console.error("Failed to delete client: ", error);
    } finally {
      setDialogOpen(false);
    }
  };

  return (
    <>
      <div className="flex flex-row space-y-0 space-x-2">
        {/* Edit Button */}
        <button
          className="btn btn-warning text-white p-2 rounded flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${RouteConfig.CLIENTS}/${data.id}/edit`);
          }}
        >
          <FaEdit />
        </button>

        {/* Delete Button */}
        <button
          className="btn bg-red-500 text-white p-2 rounded flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            setDialogOpen(true); // Open the confirmation dialog
          }}
        >
          <FaTrashAlt />
        </button>

        {/* Add Invoice Button */}
        <button
          className="btn btn-success text-white p-2 rounded flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${data.id}/add-invoices`);
          }}
        >
          <FaDollarSign />
        </button>
      </div>
      <SnackbarComponent />
      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Delete Client"
        message="Are you sure you want to delete this client? This action cannot be undone."
        onConfirm={(e) => {
          e.stopPropagation();
          handleDeleteClient();
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setDialogOpen(false);
        }}
      />
    </>
  );
};

const ClientTable = ({ clients, orderBy, order, onSort, calculateDays }) => {
  const navigate = useNavigate();

  const handleRowClick = (client) => {
    navigate(`${RouteConfig.CLIENTS}/${client.id}`);
  };

  const getAvatar = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  const clientRows = clients.map((client) => ({
    id: client.id,
    image: client.imageUrl ? (
      <img
        src={client.imageUrl}
        alt={client.name}
        className="w-20 h-20 rounded-full"
      />
    ) : (
      <div className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center">
        <span className="text-xl font-bold">{getAvatar(client.name)}</span>
      </div>
    ),
    name: client.name,
    phone: client.phone || "N/A",
    membership: (
      <>
        {client.membershipEndDate || "N/A"}
        {client.membershipEndDate && (
          <p
            className={`${
              calculateDays(client.membershipEndDate) >= 0
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {calculateDays(client.membershipEndDate) >= 0
              ? `${calculateDays(client.membershipEndDate)} days left`
              : `${Math.abs(calculateDays(client.membershipEndDate))} days expired`}
          </p>
        )}
      </>
    ),
  }));

  return (
    <Table
      headers={{
        values: ["id", "image", "Name", "Phone", "Membership"],
        sortable: ["Name", "Membership"],
      }}
      rows={clientRows}
      onSort={onSort}
      orderBy={orderBy}
      order={order}
      onRowClick={handleRowClick}
      ActionsComponent={ActionsComponent}
    />
  );
};

const ClientCardList = ({ clients, navigate }) => {
  const calculateDays = (expiryDate) => {
    const today = dayjs();
    const expiry = dayjs(expiryDate);
    const difference = expiry.diff(today, "day");
    return difference;
  };

  const getAvatar = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  return (
    <div className="grid grid-cols-1 gap-4 cursor-pointer">
      {clients.map((client) => (
        <div
          key={client.id}
          className="border rounded p-4 bg-white shadow-sm"
          onClick={(e) => {
            navigate(`${RouteConfig.CLIENTS}/${client.id}`);
          }}
        >
          <div className="flex items-center mb-2">
            {client.imageUrl ? (
              <img
                src={client.imageUrl}
                alt={client.name}
                className="w-12 h-12 rounded-full mr-4"
              />
            ) : (
              <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center mr-4">
                <span className="text-xl font-bold">
                  {getAvatar(client.name)}
                </span>
              </div>
            )}
            <div>
              <h2 className="text-lg font-semibold">{client.name}</h2>
              <p>{client.phone || "N/A"}</p>
            </div>
          </div>
          <div>
            <div>
              Membership : {client.membershipEndDate || "N/A"}
              {client.membershipEndDate && (
                <p
                  className={` ${
                    calculateDays(client.membershipEndDate) >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {calculateDays(client.membershipEndDate) >= 0
                    ? `${calculateDays(client.membershipEndDate)} days left`
                    : `${Math.abs(
                        calculateDays(client.membershipEndDate)
                      )} days expired`}
                </p>
              )}
            </div>
            <div className="mt-4">
              <ActionsComponent client={client} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
const PaginationControls = ({
  page,
  rowsPerPage,
  totalElements,
  onPageChange,
  onRowsPerPageChange,
}) => (
  <div className="flex flex-col md:flex-row justify-between items-center py-2 space-y-2 md:space-y-0">
    <div className="flex items-center">
      <span>Rows per page:</span>
      <select
        value={rowsPerPage}
        onChange={onRowsPerPageChange}
        className="ml-2 p-1 border rounded text-gray-800"
      >
        {[5, 10, 25, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>
    <div className="flex items-center space-x-2">
      <span>
        Page: {page + 1} of {Math.ceil(totalElements / rowsPerPage)}
      </span>
      <button
        onClick={() => onPageChange(page - 1)}
        disabled={page === 0}
        className="p-2 border rounded bg-white text-gray-800 hover:bg-gray-700 hover:text-white disabled:bg-gray-300 disabled:text-gray-600"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(page + 1)}
        disabled={page >= Math.ceil(totalElements / rowsPerPage) - 1}
        className="p-2 border rounded bg-blue-600 text-white hover:bg-blue-700 disabled:bg-gray-300 disabled:text-gray-600"
      >
        Next
      </button>
    </div>
  </div>
);


export default ClientListScreen;
