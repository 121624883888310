import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetClientByIdQuery,
  useUpdateClientMutation,
} from "../../redux/clientApiSlice";
import ClientForm from "./ClientForm"; // Ensure the correct path
import FullScreenLoading from "../../components/FullScreenLoading"; // Ensure the correct path

const EditClientScreen = () => {
  const { clientId } = useParams();
  const { data: clientData, isLoading: isFetching } =
    useGetClientByIdQuery(clientId);
  const [updateClient, { isLoading }] = useUpdateClientMutation();
  const [initialData, setInitialData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (clientData) {
      setInitialData({
        name: clientData.name || "",
        address: clientData.address || "",
        gender: clientData.gender || "",
        phone: clientData.phone || "",
        email: clientData.email || "",
        emergencyContact: clientData.emergencyContact || "",
        dateOfBirth: clientData.dateOfBirth || "",
        joinedDate: clientData.joinedDate || "",
        membershipEndDate: clientData.membershipEndDate || "",
        notes: clientData.notes || "",
        imageUrl: clientData.imageUrl || null,
        clientInfoInDevice: {
          card: clientData?.clientInfoInDevice?.card || "",
          password: clientData?.clientInfoInDevice?.password || "",
          privilege: String(clientData?.clientInfoInDevice?.privilege || "0"),
        },
        accessDeviceIds: clientData.accessDeviceIds || [],
      });
    }
  }, [clientData]);

  const handleSubmit = async ({ clientDto, file }) => {
    await updateClient({ id: clientId, clientDto, file }).unwrap();
    navigate(-1);
  };

  if (isFetching || !initialData) return <FullScreenLoading />;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Client</h1>
      <ClientForm
        initialData={initialData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditClientScreen;
