import { useState } from "react";
import { useSelector } from "react-redux";
import FetchWrapper from "../../components/FetchWrapper";
import {
  useGetActiveExpiredClientsByGymIdQuery,
  useUpdateClientMutation,
} from "../../redux/clientApiSlice";
import PaginationControls from "../../components/PaginationControls";
import useSnackbar from "../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../../config/RouteConfig";
import { selectGym } from "../../redux/gymSlice";

const ExpiredUsersList = () => {
  const navigate = useNavigate();
  const { gymId } = useSelector(selectGym);
  const [page, setPage] = useState(0);
  const SIZE = 25;
  const { data, error, isLoading } = useGetActiveExpiredClientsByGymIdQuery({
    gymId,
    page,
    size: SIZE,
  });
  const [updateClient] = useUpdateClientMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const calculateExpiredDays = (endDate) => {
    const today = new Date();
    const expDate = new Date(endDate);
    return Math.floor((today - expDate) / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleSetInactive = async (clientId) => {
    try {
      await updateClient({
        id: clientId,
        clientDto: { activeMember: false },
        file: null,
      });
      showSnackbar("Client has been set to inactive.", "success");
    } catch (error) {
      showSnackbar("Failed to set client to inactive.", "error");
    }
  };

  const getAvatar = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Expired Clients</h2>
        <ul>
          {data?.content.map((client) => (
            <li
              key={client.id}
              className="flex items-center justify-between p-4 rounded-sm shadow-md  mb-4 cursor-pointer"
              onClick={() => navigate(`${RouteConfig.CLIENTS}/${client.id}`)} // Navigate to client detail
            >
              <div className="flex items-center cursor-pointer">
                {client.imageUrl ? (
                  <img
                    src={client.imageUrl}
                    alt={client.name}
                    className="w-14 h-14 rounded-full"
                  />
                ) : (
                  <div className="w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center">
                    <span className="text-xl font-bold">
                      {getAvatar(client.name)}
                    </span>
                  </div>
                )}
                <div className="ml-2">
                  <p className="font-bold">{client.name || "Unnamed Client"}</p>
                  <p className="text-gray-600">
                    Expired Days:{" "}
                    {calculateExpiredDays(client.membershipEndDate)}
                  </p>
                </div>
              </div>

              <button
                onClick={(e) => {
                  e.stopPropagation(); // This stops the event from bubbling up to parent elements
                  handleSetInactive(client.id);
                }}
                className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-700 transition duration-150 mr-2"
              >
                Set Inactive
              </button>
            </li>
          ))}
        </ul>
        {data?.paginationResponse.totalPages > 1 ? (
          <PaginationControls
            currentPage={page}
            itemsPerPage={SIZE}
            totalItems={data?.totalElements}
            onPrevious={() => setPage((prev) => prev - 1)}
            onNext={() => setPage((prev) => prev + 1)}
          />
        ) : null}
        <SnackbarComponent /> {/* Render the Snackbar component */}
      </div>
    </FetchWrapper>
  );
};

export default ExpiredUsersList;
