import { useState, useEffect } from "react";
import dayjs from "dayjs";
import useSnackbar from "../../../hooks/useSnackbar";
import DynamicDropdown from "../../../components/DynamicDropdown";
import { useGetPricingPlansByGymIdQuery } from "../../../redux/gymPricingPlanApiSlice";
import { useGetLastMembershipEndDateByClientIdQuery } from "../../../redux/clientInvoiceApiSlice"; // Import the query
import FormCardWrapper from "../../../components/FormCardWrapper";
import { useSelector } from "react-redux";
import { selectGym } from "../../../redux/gymSlice";
import { useNavigate } from "react-router-dom";

const InvoiceForm = ({ initialData, onSubmit, isLoading }) => {
  const [invoiceData, setInvoiceData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { gymId } = useSelector(selectGym);
  const clientId = initialData?.clientId; // Assuming clientId is part of initialData
  const navigate = useNavigate();

  // Fetch the last membership end date
  const { data: lastPaymentDateData } =
    useGetLastMembershipEndDateByClientIdQuery(clientId, {
      skip: !clientId, // Skip if clientId is not available
    });

  useEffect(() => {
    setInvoiceData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updated handleSelect method to store pricingPlanDto as an object with an id
  const handleSelect = (field) => (value) => {
    setInvoiceData((prev) => ({
      ...prev,
      [field]: { id: value }, // Set as an object with id field
    }));
  };

  const handleSetStartDate = (date) => {
    setInvoiceData((prev) => ({
      ...prev,
      membershipStartDate: date,
    }));
  };

  const handleSetEndDate = (months) => {
    const startDate = invoiceData.membershipStartDate
      ? dayjs(invoiceData.membershipStartDate)
      : dayjs();
    const newEndDate = startDate.add(months, "month").format("YYYY-MM-DD");
    setInvoiceData((prev) => ({
      ...prev,
      membershipEndDate: newEndDate,
    }));
  };

  const validate = () => {
    let tempErrors = {};

    if (!invoiceData.membershipStartDate)
      tempErrors.membershipStartDate = "Membership Start Date is required.";
    if (!invoiceData.membershipEndDate)
      tempErrors.membershipEndDate = "Membership End Date is required.";
    if (!invoiceData.pricingPlanDto?.id)
      tempErrors.pricingPlanDto = "Pricing Plan is required.";
    if (!invoiceData.totalAmount || invoiceData.totalAmount <= 0)
      tempErrors.totalAmount = "Total Amount can not be zero.";
    if (invoiceData.discountedAmount <= 0)
      tempErrors.discountedAmount = "Discounted Amount can not be zero.";
    if (
      invoiceData.discountedAmount &&
      invoiceData.discountedAmount > invoiceData.totalAmount
    )
      tempErrors.discountedAmount =
        "Discounted Amount cannot exceed Total Amount.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      await onSubmit(invoiceData);
      showSnackbar("Invoice processed successfully!", "success");
      setInvoiceData(initialData);
    } catch (err) {
      console.error("Failed to process invoice:", err);
      showSnackbar(
        "Failed to process invoice. " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSubmit}>
        <FormCardWrapper title="Invoice Details">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Pricing Plan Selection - Full Width */}
            <div className="md:col-span-2 mb-4 ">
              <div className="flex items-end">
                <DynamicDropdown
                  label="Pricing Plan"
                  useQueryHook={useGetPricingPlansByGymIdQuery}
                  onSelect={handleSelect("pricingPlanDto")}
                  value={invoiceData.pricingPlanDto?.id || ""} // Pass the id for the dropdown value
                  queryParams={{ gymId: gymId }}
                />
                <button
                  onClick={() => navigate(`/gyms/pricing/${gymId}`)} // Navigate to the create plan page
                  className="ml-4 btn btn-neutral "
                >
                  Create Plan
                </button>
              </div>
              {errors.pricingPlanDto && (
                <div className="text-red-500">{errors.pricingPlanDto}</div>
              )}
            </div>

            {/* Membership Dates */}
            <div className="mb-4">
              <label className="block text-gray-700">
                Membership Start Date
              </label>
              <input
                type="date"
                name="membershipStartDate"
                value={invoiceData.membershipStartDate}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
              {errors.membershipStartDate && (
                <div className="text-red-500">{errors.membershipStartDate}</div>
              )}
              <div className="mt-2 flex space-x-2">
                <button
                  type="button"
                  className="bg-gray-200 text-gray-700 px-2 py-1 rounded"
                  onClick={() =>
                    handleSetStartDate(dayjs().format("YYYY-MM-DD"))
                  }
                >
                  Today's Date
                </button>
                <button
                  type="button"
                  className="bg-gray-200 text-gray-700 px-2 py-1 rounded"
                  onClick={() => handleSetStartDate(lastPaymentDateData)}
                  disabled={!lastPaymentDateData}
                >
                  Last Payment Date
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Membership End Date</label>
              <input
                type="date"
                name="membershipEndDate"
                value={invoiceData.membershipEndDate}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
              {errors.membershipEndDate && (
                <div className="text-red-500">{errors.membershipEndDate}</div>
              )}
              {invoiceData.membershipStartDate && (
                <div className="mt-2 flex space-x-2">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-2 py-1 rounded"
                    onClick={() => handleSetEndDate(1)}
                  >
                    Add 1 Month
                  </button>
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-2 py-1 rounded"
                    onClick={() => handleSetEndDate(3)}
                  >
                    Add 3 Months
                  </button>
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-2 py-1 rounded"
                    onClick={() => handleSetEndDate(6)}
                  >
                    Add 6 Months
                  </button>
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-2 py-1 rounded"
                    onClick={() => handleSetEndDate(12)}
                  >
                    Add 12 Months
                  </button>
                </div>
              )}
            </div>

            {/* Amount Fields */}
            <div className="mb-4">
              <label className="block text-gray-700">Total Amount</label>
              <input
                type="number"
                name="totalAmount"
                value={invoiceData.totalAmount}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
              {errors.totalAmount && (
                <div className=" text-red-500">{errors.totalAmount}</div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Discounted Amount</label>
              <input
                type="number"
                name="discountedAmount"
                value={invoiceData.discountedAmount}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
              {errors.discountedAmount && (
                <div className="text-red-500">{errors.discountedAmount}</div>
              )}
            </div>

            <div className="mb-4 col-span-1 md:col-span-2 flex justify-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded max-w-lg w-full"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Process Invoice"}
              </button>
            </div>
          </div>
        </FormCardWrapper>
      </form>
      <SnackbarComponent />
    </div>
  );
};

export default InvoiceForm;
