import { FaGlobe, FaUser, FaUsers, FaCreditCard, FaBell, FaClipboard, FaCheckCircle, FaLock } from "react-icons/fa"; // Importing required icons from Font Awesome
import heroImage from "../../assets/gym.jpg";

const GymCms = () => {
  return (
    <div>
      <HeroSection />
      <FeaturesSection />
      {/* <TestimonialsSection /> */}
      {/* <PricingSection variant="simple" /> */}
    </div>
  );
};

const HeroSection = () => {
  return (
    <div className="relative text-white py-20">
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-500 opacity-75"></div>

      <div className="relative z-10 max-w-7xl mx-auto px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex flex-col items-center md:items-start md:w-1/2">
            <h1 className="text-4xl md:text-6xl font-bold mb-4 text-center md:text-left">
              Manage Your Gym Efficiently
            </h1>
            <p className="text-lg md:text-2xl mb-8 text-center md:text-left">
              Our all-in-one gym management system helps you handle client
              management, invoicing, automated reminders, and more. Make your
              gym operations seamless and efficient.
            </p>
            <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Get Started
            </button>
            <div className="flex items-center justify-center mt-8">
              <FaCheckCircle className="w-10 h-10 text-green-300 mr-2" />
              <span className="text-xl font-bold">Trusted by 100+ gyms</span>
            </div>
          </div>
          <div className="mt-8 md:mt-0 md:w-1/2">
            <img
              src={heroImage}
              alt="Gym Management"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const features = [
  {
    title: "Personal Website",
    description: "Comprehensive tools to manage gym operations efficiently.",
    icon: FaGlobe,
  },
  {
    title: "Client Management",
    description: "Easily manage client information and track memberships.",
    icon: FaUser,
  },
  {
    title: "Staff Management",
    description: "Organize and manage staff schedules, roles, and attendance.",
    icon: FaUsers,
  },
  {
    title: "Payment Management",
    description: "Efficiently track payments, invoices, and membership dues.",
    icon: FaCreditCard,
  },
  {
    title: "Bulk SMS Notifications",
    description: "Send automated SMS reminders for memberships and promotions.",
    icon: FaBell,
  },
  {
    title: "Access Control Device",
    description: "Secure your gym with advanced access control via fingerprint and RFID card technology.",
    icon: FaLock,
  },
];

const FeaturesSection = () => (
  <div className="py-20">
    <div className="container mx-auto px-6 lg:px-8">
      <h2 className="text-4xl font-bold mb-12">Features</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
          >
            <div className="text-blue-600 mb-4">
              <feature.icon className="w-12 h-12" />
            </div>
            <h3 className="text-xl font-bold mb-2 text-center">
              {feature.title}
            </h3>
            <p className="text-gray-600 text-center">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default GymCms;
