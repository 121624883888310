import { useState } from "react";
import { useSetJudgeResultMutation } from "../../redux/judgeApiSlice";
import JudgeDecision from "./JudgeDecision";
import ResetJudgeResults from "./ResetJudgeResults";

const JudgeResultSetter = () => {
  const [judgeId, setJudgeId] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [pin, setPin] = useState("");
  const [setJudgeResult, { isLoading, isError, isSuccess }] = useSetJudgeResultMutation();

  const handleJudgeChange = (event) => {
    setJudgeId(parseInt(event.target.value, 10));
  };

  const handleSetResult = async (result) => {
    try {
      await setJudgeResult({ judgeId, result }).unwrap();
    } catch (error) {
      console.error("Error setting judge result:", error);
      alert("Failed to set judge result.");
    }
  };

  const verifyPin = () => {
    if (pin === "111") {
      setIsDialogOpen(false);
    } else {
      alert("Invalid PIN. Please try again.");
    }
  };

  return (
    <>
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h2 className="text-2xl mb-4">Enter PIN</h2>
            <input
              type="password"
              className="border p-2 w-full mb-4"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Enter PIN"
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={verifyPin}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {!isDialogOpen && (
          <div
          style={{
            textAlign: "center",
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            height: "100vh",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="judgeId">Select Judge: </label>
            <select id="judgeId" value={judgeId} onChange={handleJudgeChange}>
              <option value={1}>Judge 1</option>
              <option value={2}>Judge 2</option>
              <option value={3}>Judge 3</option>
            </select>
          </div>
          <JudgeDecision />
    
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "400px",
              gap: "10px",
              borderWidth: "1px",
            }}
          >
            <button
              style={{
                padding: "20px 0",
                fontSize: "18px",
                backgroundColor: "green",
                color: "#fff",
                flex: 1,
              }}
              onClick={() => handleSetResult(true)}
              disabled={isLoading}
            >
              Pass
            </button>
            <button
              style={{
                padding: "20px 0",
                fontSize: "18px",
                backgroundColor: "red",
                color: "#fff",
                flex: 1,
              }}
              onClick={() => handleSetResult(false)}
              disabled={isLoading}
            >
              Fail
            </button>
          </div>
          <ResetJudgeResults/>
    
          {isError && (
            <p style={{ color: "red" }}>Failed to set result. Please try again.</p>
          )}
          {isSuccess && <p style={{ color: "green" }}>Result set successfully!</p>}
        </div>
      )}
    </>
  );
};

export default JudgeResultSetter;
