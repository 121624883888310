import { useGetJudgeResultsQuery } from "../../redux/judgeApiSlice"; // Adjust the path as needed

const JudgeDecision = () => {
  // Set pollingInterval to 1000 ms (1 second) to fetch data every second
  const { data } = useGetJudgeResultsQuery(undefined, {
    pollingInterval: 1000,
  });

  if (!data || !data.isStarted) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        maxWidth: "500px",
        margin: "0 auto",
        backgroundColor: "#cdcdcd",
      }}
    >
      {Object.entries(data.judgeResults).map(([judgeId, result]) => (
        <div
          key={judgeId}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              borderWidth: "1px",
              backgroundColor: result ? "#ffffff" : "#d40303",
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default JudgeDecision;
