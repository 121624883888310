import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AccessControlDeviceForm from "./AccessControlDeviceForm"; // Ensure the correct path
import FullScreenLoading from "../../components/FullScreenLoading"; // Ensure the correct path
import {
  useGetAccessControlDeviceByIdQuery,
  useUpdateAccessControlDeviceMutation,
} from "../../redux/accessDeviceApiSlice";

const EditDeviceScreen = () => {
  const { deviceId } = useParams();
  const { data: deviceData, isLoading: isFetching } =
    useGetAccessControlDeviceByIdQuery(deviceId);
  const [updateAccessControlDevice, { isLoading }] =
    useUpdateAccessControlDeviceMutation();
  const [initialData, setInitialData] = useState(null);

  const navigate = useNavigate();

 

  useEffect(() => {
    if (deviceData) {
      setInitialData({
        id: deviceData.id,
        door: deviceData.door || "",
        deviceName: deviceData.deviceName || "",
        serialNumber: deviceData.serialNumber || "",
        gymId: deviceData.gymId, // Ensure this is included for update
      });
    }
  }, [deviceData]);

  const handleSubmit = async (deviceDto) => {
    await updateAccessControlDevice({ id: deviceId, deviceDto }).unwrap();
    navigate(-1);
  };

  if (isFetching || !initialData) return <FullScreenLoading />;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Device</h1>
      <AccessControlDeviceForm
        initialData={initialData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditDeviceScreen;
