import { apiSlice } from "./apiSlice";

export const attendanceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch total distinct days client attended in a month (only "IN" status)
    getTotalDaysClientInAttendanceInMonth: builder.query({
      query: ({ clientId, month, year }) => ({
        url: `/api/attendance/client/total-days`,
        params: { clientId, month, year },
      }),
      providesTags: ["Attendance"],
    }),

    // Fetch "IN" and "OUT" records for a client in a specific month
    getClientInOutRecordsForMonth: builder.query({
      query: ({ clientId, month, year }) => ({
        url: `/api/attendance/client/in-out`,
        params: { clientId, month, year },
      }),
      providesTags: ["Attendance"],
    }),

    // Fetch total number of clients who checked "IN" to a gym in a month
    getTotalClientsInAttendanceInMonthByGym: builder.query({
      query: ({ gymId, month, year }) => ({
        url: `/api/attendance/gym/total-clients-in`,
        params: { gymId, month, year },
      }),
      providesTags: ["Attendance"],
    }),

    // Fetch most traffic day in a gym (returns Map<String, Long> for day -> count)
    getMostTrafficDayByGym: builder.query({
      query: (gymId) => ({
        url: `/api/attendance/gym/most-traffic-day`,
        params: { gymId },
      }),
      providesTags: ["Attendance"],
    }),

    // Fetch most traffic time in a single day for a gym (returns Map<String, Long> for hour -> count)
    getMostTrafficTimeByGymAndDay: builder.query({
      query: ({ gymId, day }) => ({
        url: `/api/attendance/gym/most-traffic-time`,
        params: { gymId, day },
      }),
      providesTags: ["Attendance"],
    }),

    // Fetch attendance for the last 12 months for a client (auto date range)
    getLast12MonthsAttendance: builder.query({
      query: (clientId) => ({
        url: `/api/attendance/client/last-12-months`,
        params: { clientId },
      }),
      providesTags: ["Attendance"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTotalDaysClientInAttendanceInMonthQuery,
  useGetClientInOutRecordsForMonthQuery,
  useGetTotalClientsInAttendanceInMonthByGymQuery,
  useGetMostTrafficDayByGymQuery,
  useGetMostTrafficTimeByGymAndDayQuery,
  useGetLast12MonthsAttendanceQuery, // Added for fetching last 12 months' data
} = attendanceApiSlice;
