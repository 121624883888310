import { apiSlice } from "./apiSlice";

export const accessDeviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch a single access control device by ID
    getAccessControlDeviceById: builder.query({
      query: (id) => `/api/access-control-devices/${id}`,
      providesTags: ["AccessControlDevices"],
    }),

    // Fetch access control devices by gym ID with pagination
    getDevicesByGymWithPagination: builder.query({
      query: ({ gymId, page = 0, size = 25 }) => ({
        url: `/api/access-control-devices/gyms/${gymId}`,
        params: { page, size },
      }),
      providesTags: ["AccessControlDevices"],
    }),

    // Fetch access control devices by client ID
    getDevicesByClientId: builder.query({
      query: (clientId) => `/api/access-control-devices/client/${clientId}`, // Adjust the URL to match your controller
      providesTags: ["AccessControlDevices"],
    }),

    // Create a new access control device
    createAccessControlDevice: builder.mutation({
      query: (deviceDto) => ({
        url: `/api/access-control-devices`,
        method: "POST",
        body: deviceDto,
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),

    // Update an existing access control device by ID
    updateAccessControlDevice: builder.mutation({
      query: ({ id, deviceDto }) => ({
        url: `/api/access-control-devices/${id}`,
        method: "PUT",
        body: deviceDto,
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),

    // Delete an access control device by ID
    deleteAccessControlDevice: builder.mutation({
      query: (id) => ({
        url: `/api/access-control-devices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),

    addAllClientsToDevice: builder.mutation({
      query: ({ deviceId }) => ({
        url: `/api/access-control-devices/${deviceId}/add-all-clients`,
        method: "POST",
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),

    addClientsToDevice: builder.mutation({
      query: ({ deviceId, clientIds }) => ({
        url: `/api/access-control-devices/${deviceId}/add-clients`,
        method: "POST",
        body: clientIds, // Send the list of client IDs as the request body
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),


    // Remove clients from a device
    removeClientsFromDevice: builder.mutation({
      query: ({ deviceId, clientIds }) => ({
        url: `/api/access-control-devices/${deviceId}/remove-clients`,
        method: "DELETE",
        body: clientIds, // Send the list of client IDs as the request body
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),
   // Add Remote Card to a device
   addRemoteCard: builder.mutation({
    query: ({ deviceId, clientId }) => ({
      url: `/api/access-control-devices/${deviceId}/remote-card-client/${clientId}`,
      method: "POST"
    }),
    invalidatesTags: ["AccessControlDevices"],
  }),

    
    // Add Remote fingerprint to a device
    addRemoteFingerPrint: builder.mutation({
      query: ({ deviceId, clientId }) => ({
        url: `/api/access-control-devices/${deviceId}/remote-fingerprint-client/${clientId}`,
        method: "POST"
      }),
      invalidatesTags: ["AccessControlDevices"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAccessControlDeviceByIdQuery,
  useGetDevicesByGymWithPaginationQuery,
  useCreateAccessControlDeviceMutation,
  useUpdateAccessControlDeviceMutation,
  useDeleteAccessControlDeviceMutation,
  useAddAllClientsToDeviceMutation, //add all clients to device
  useAddClientsToDeviceMutation,    // Add clients to device
  useRemoveClientsFromDeviceMutation, // Remove clients from device
  useGetDevicesByClientIdQuery, // Fetch devices by client ID

  useAddRemoteCardMutation,
  useAddRemoteFingerPrintMutation,
} = accessDeviceApiSlice;
