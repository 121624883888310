import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import CustomFileInput from "../../components/CustomFileInput";
import useSnackbar from "../../hooks/useSnackbar";
import {
  FaUserAlt,
  FaPhone,
  FaHome,
  FaEnvelope,
  FaExclamationTriangle,
  FaBirthdayCake,
  FaClipboardList,
  FaCalendarAlt,
  FaPowerOff,
  FaLock,
  FaFingerprint,
  FaTrash,
  FaPlus,
  FaCheckCircle,
} from "react-icons/fa";
import FormCardWrapper from "../../components/FormCardWrapper";
import FormField from "../../components/FormField";
import { useGetDevicesByGymWithPaginationQuery } from "../../redux/accessDeviceApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";

const ClientForm = ({ initialData, onSubmit, isLoading, showDeviceList }) => {
  const [clientData, setClientData] = useState(initialData);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const { gymId } = useSelector(selectGym);

  useEffect(() => {
    setClientData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClientData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDeviceChange = (e) => {
    const { name, value } = e.target;
    setClientData((prev) => ({
      ...prev,
      clientInfoInDevice: {
        ...prev.clientInfoInDevice,
        [name]: value,
      },
    }));
  };
  // Function to handle the accessDeviceIds update
  const handleAccessDeviceIdsChange = (deviceId) => {
    setClientData((prev) => {
      const currentAccessDeviceIds = prev.accessDeviceIds || [];

      // Check if the device ID is already in the array
      const isDeviceSelected = currentAccessDeviceIds.includes(deviceId);

      return {
        ...prev,
        accessDeviceIds: isDeviceSelected
          ? currentAccessDeviceIds.filter((id) => id !== deviceId) // Remove device if selected
          : [...currentAccessDeviceIds, deviceId], // Add device if not selected
      };
    });
  };

  const handleFileChange = (selectedFile) => {
    setFile(selectedFile);
  };

  const validate = () => {
    let tempErrors = {};
    if (!clientData.name) tempErrors.name = "Name is required.";
    if (!clientData.phone) tempErrors.phone = "Phone is required.";
    if (!clientData.gender) tempErrors.gender = "Gender is required."; // Validation for gender

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const clientDto = {
        ...clientData,
        clientInfoInDevice: {
          card: clientData.clientInfoInDevice.card,
          password: clientData.clientInfoInDevice.password,
          privilege: parseInt(clientData.clientInfoInDevice.privilege, 10),
        },
      };

      await onSubmit({ clientDto, file });
      showSnackbar("Client saved successfully!", "success");
      setClientData(initialData);
      setFile(null);
    } catch (err) {
      console.error("Failed to save client:", err);
      showSnackbar(
        "Failed to save client: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  const addMonths = (months) => {
    const newDate = dayjs().add(months, "month").format("YYYY-MM-DD");
    setClientData((prev) => ({
      ...prev,
      membershipEndDate: newDate,
    }));
  };

  return (
    <div className="container mx-auto">
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <BasicInfo
          inputFields={inputFields}
          clientData={clientData}
          handleChange={handleChange}
          errors={errors}
          addMonths={addMonths}
        />

        {showDeviceList && (
          <DeviceList
            gymId={gymId}
            selectedDeviceIds={clientData.accessDeviceIds}
            handleAccessDeviceIdsChange={handleAccessDeviceIdsChange}
          />
        )}

        <DeviceSection
          clientData={clientData}
          handleDeviceChange={handleDeviceChange}
        />

        <ImageUploadSection handleFileChange={handleFileChange} file={file} />
        <div className="col-span-1 md:col-span-2 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white btn max-w-lg w-full"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Client"}
          </button>
        </div>
      </form>
      <SnackbarComponent />
    </div>
  );
};

export default ClientForm;

const BasicInfo = ({
  inputFields,
  clientData,
  handleChange,
  errors,
  addMonths,
}) => (
  <FormCardWrapper title="Basic Info">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {inputFields.map((field, index) => {
        if (field.name === "gender") {
          return (
            <div key={index} className="mb-4 flex flex-col">
              <label className="text-gray-700 flex items-center mb-1">
                {field.icon}
                {field.label}
              </label>
              <div className="flex w-full border rounded space-x-4 bg-white pl-2 py-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="MALE"
                    checked={clientData.gender === "MALE"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Male
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="FEMALE"
                    checked={clientData.gender === "FEMALE"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Female
                </label>
              </div>
              {errors.gender && (
                <div className="text-red-500">{errors.gender}</div>
              )}
            </div>
          );
        }

        return (
          <FormField
            key={index}
            field={field}
            value={clientData[field.name] ?? ""}
            onChange={handleChange}
            error={errors[field.name]}
          />
        );
      })}
      <div className="flex flex-wrap gap-4">
        {["1", "3", "6", "12"].map((month) => (
          <button
            key={month}
            type="button"
            className="btn btn-outline btn-primary"
            onClick={() => addMonths(month)}
          >
            Add {month} Month{month > 1 ? "s" : ""}
          </button>
        ))}
      </div>
    </div>
  </FormCardWrapper>
);
const DeviceSection = ({ clientData, handleDeviceChange }) => (
  <FormCardWrapper title="Device Information">
    <div className="grid grid-cols-1 gap-4">
      {/* Privilege Level */}
      <div className="mb-4 flex flex-col">
        <label className="text-gray-700 flex items-center mb-1">
          Privilege Level
        </label>
        <div className="flex w-full border rounded space-x-4 bg-white pl-2 py-2">
          <label className="flex items-center">
            <input
              type="radio"
              name="privilege"
              value={"0"}
              checked={clientData.clientInfoInDevice?.privilege === "0"}
              onChange={handleDeviceChange}
              className="mr-2"
            />
            Normal User
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="privilege"
              value={"14"}
              checked={clientData.clientInfoInDevice?.privilege === "14"}
              onChange={handleDeviceChange}
              className="mr-2"
            />
            Super Admin
          </label>
        </div>
      </div>

      {/* Card */}
      <div className="mb-4 flex flex-col">
        <label htmlFor="card" className="text-gray-700 mb-1">
          Card Number
        </label>
        <input
          type="text"
          id="card"
          name="card"
          value={clientData.clientInfoInDevice?.card || ""}
          onChange={handleDeviceChange}
          className="border rounded px-2 py-2"
        />
      </div>

      {/* Password */}
      <div className="mb-4 flex flex-col">
        <label htmlFor="password" className="text-gray-700 mb-1">
          Password
        </label>
        <input
          type="text"
          id="password"
          name="password"
          value={clientData.clientInfoInDevice?.password || ""}
          onChange={handleDeviceChange}
          className="border rounded px-2 py-2"
        />
      </div>
    </div>
  </FormCardWrapper>
);

const ImageUploadSection = ({ handleFileChange, file }) => (
  <FormCardWrapper title="Profile Image">
    <div className="flex justify-center">
      <CustomFileInput
        name="Change Profile"
        onChange={handleFileChange}
        reset={!file}
      />
    </div>
  </FormCardWrapper>
);

const DeviceList = ({
  gymId,
  selectedDeviceIds,
  handleAccessDeviceIdsChange,
}) => {
  const {
    data: gymDevicesResponse,
    error: gymError,
    isLoading: gymLoading,
  } = useGetDevicesByGymWithPaginationQuery({ gymId });

  const gymDevices = gymDevicesResponse?.content || [];

  // Check if the list is empty
  const isEmptyList = !gymDevices.length;

  return (
    <FormCardWrapper title={"Device Info"}>
      <FetchWrapper
        isError={!!gymError}
        error={gymError}
        isLoading={gymLoading}
        isEmptyList={isEmptyList}
        emptyListInfo="devices"
      >
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-lg font-bold flex items-center mb-2">
            Device List
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {gymDevices.map((device) => {
              // Check if the device is selected by verifying if the device.id is in the selectedDeviceIds array
              const isSelected = selectedDeviceIds.includes(device.id);

              return (
                <div
                  key={device.id}
                  className={`p-4 rounded-lg border relative transition-all duration-300 ${
                    isSelected ? "bg-green-100" : "bg-red-100"
                  }`}
                >
                  <h3 className="text-lg font-bold mb-2">
                    {device.deviceName || "Unnamed Device"}
                  </h3>
                  <p>
                    <FaLock className="inline-block mr-2 text-gray-600" />
                    Door: {device.door || "Unknown"}
                  </p>
                  <p>
                    <FaFingerprint className="inline-block mr-2 text-indigo-600" />
                    Fingerprint Count: {device.fingers || "0"}
                  </p>
                  <div className="flex justify-between items-center mt-4">
                    {/* Conditionally show Add/Remove Device button based on the device selection */}
                    {isSelected ? (
                      <button
                        type="button" // Prevent form submission
                        onClick={() => handleAccessDeviceIdsChange(device.id)}
                        className="text-sm px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        <FaTrash className="inline-block mr-2" />
                        Remove
                      </button>
                    ) : (
                      <button
                        type="button" // Prevent form submission
                        onClick={() => handleAccessDeviceIdsChange(device.id)}
                        className="text-sm px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        <FaPlus className="inline-block mr-2" />
                        Add
                      </button>
                    )}
                  </div>
                  {/* Active/Inactive State Indicator */}
                  <div className="absolute top-2 right-2">
                    {isSelected ? (
                      <FaCheckCircle
                        className="text-green-500"
                        title="Selected"
                      />
                    ) : (
                      <FaPowerOff
                        className="text-red-500"
                        title="Not Selected"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </FetchWrapper>
    </FormCardWrapper>
  );
};

const inputFields = [
  {
    label: "Name",
    name: "name",
    type: "text",
    icon: <FaUserAlt className="text-xl text-indigo-500 mr-2" />,
    required: true,
  },
  {
    label: "Gender",
    name: "gender",
    type: "radio",
    icon: <FaUserAlt className="text-xl text-indigo-500 mr-2" />,
    required: true,
  },
  {
    label: "Phone",
    name: "phone",
    type: "text",
    icon: <FaPhone className="text-xl text-green-500 mr-2" />,
    required: true,
  },

  {
    label: "Address",
    name: "address",
    type: "text",
    icon: <FaHome className="text-xl text-blue-500 mr-2" />,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    icon: <FaEnvelope className="text-xl text-blue-500 mr-2" />,
  },
  {
    label: "Emergency Contact",
    name: "emergencyContact",
    type: "text",
    icon: <FaExclamationTriangle className="text-xl text-red-500 mr-2" />,
    required: false,
  },
  {
    label: "Date of Birth",
    name: "dateOfBirth",
    type: "date",
    icon: <FaBirthdayCake className="text-xl text-yellow-500 mr-2" />,
  },
  {
    label: "Joined Date",
    name: "joinedDate",
    type: "date",
    icon: <FaClipboardList className="text-xl text-purple-500 mr-2" />,
  },

  {
    label: "Membership End Date",
    name: "membershipEndDate",
    type: "date",
    icon: <FaCalendarAlt className="text-xl text-orange-500 mr-2" />,
    required: false,
  },

  {
    label: "Notes",
    name: "notes",
    type: "textarea",
    className: "col-span-1 md:col-span-2",
    rows: 3,
  },
];
