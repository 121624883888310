import React from "react";
import { useParams } from "react-router-dom";
import {
  FaServer,
  FaFingerprint,
  FaNetworkWired,
  FaLock,
  FaDatabase,
  FaUsers,
  FaIdCard,
  FaUserCheck,
} from "react-icons/fa";
import FetchWrapper from "../../components/FetchWrapper";
import {
  useGetAccessControlDeviceByIdQuery,
  useAddAllClientsToDeviceMutation,  // Import the mutation
} from "../../redux/accessDeviceApiSlice";

const ViewDeviceScreen = () => {
  const { deviceId } = useParams();

  const {
    data: device,
    isLoading: isLoadingDevice,
    isError: isErrorDevice,
    error: errorDevice,
  } = useGetAccessControlDeviceByIdQuery(deviceId);

  return (
    <div className="container mx-auto p-5">
      <FetchWrapper
        isLoading={isLoadingDevice}
        isError={isErrorDevice}
        error={errorDevice}
      >
        {device && <DeviceProfile device={device} deviceId={deviceId} />}
      </FetchWrapper>
    </div>
  );
};

const DeviceProfile = ({ device, deviceId }) => {
  // Calculate the total number of clients subscribed
  const totalClients = device.clientIds?.length || 0;

  // Mutation hook to add all clients to the device
  const [addAllClientsToDevice, { isLoading: isAddingClients, isSuccess, isError, error }] = useAddAllClientsToDeviceMutation();

  const handleAddAllClients = async () => {
    try {
      await addAllClientsToDevice({ deviceId }).unwrap();
      alert("Clients successfully added to the device!");
    } catch (err) {
      console.error("Failed to add clients to the device:", err);
      alert("Failed to add clients to the device.");
    }
  };

  return (
    <div className="card bg-base-100 shadow-md p-6 rounded-lg">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Device Information */}
        <div className="flex-1">
          <h2 className="text-lg font-bold mb-4">Device Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p>
              <FaServer className="inline-block mr-2 text-blue-600" />
              Device Name: {device.deviceName || "N/A"}
            </p>
            <p>
              <FaLock className="inline-block mr-2 text-purple-600" />
              Door: {device.door || "N/A"}
            </p>
            <p>
              <FaIdCard className="inline-block mr-2 text-green-600" />
              Serial Number: {device.serialNumber || "N/A"}
            </p>
            <p>
              <FaFingerprint className="inline-block mr-2 text-indigo-600" />
              Fingerprints: {device.fingers || "0"} / {device.fingersCapacity || "N/A"}
            </p>
            <p>
              <FaUsers className="inline-block mr-2 text-teal-600" />
              Users: {device.users || "0"} / {device.usersCapacity || "N/A"}
            </p>
            <p>
              <FaDatabase className="inline-block mr-2 text-orange-600" />
              Records: {device.records || "0"}
            </p>
            <p>
              <FaUserCheck className="inline-block mr-2 text-yellow-600" />
              Total Clients Subscribed: {totalClients}
            </p>
          </div>

          {/* Button to add all clients to the device */}
          <div className="mt-6">
            <button
              className="btn btn-primary"
              onClick={handleAddAllClients}
              disabled={isAddingClients}
            >
              {isAddingClients ? "Adding Clients..." : "Add All Clients to Device"}
            </button>
          </div>

          {/* Show success or error messages */}
          {isSuccess && <p className="text-green-600 mt-2">Clients successfully added to the device.</p>}
          {isError && <p className="text-red-600 mt-2">Error adding clients: {error?.message}</p>}
        </div>
      </div>
    </div>
  );
};

export default ViewDeviceScreen;
