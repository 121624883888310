import React, { useState, useEffect } from "react";
import useSnackbar from "../../hooks/useSnackbar";
import FormCardWrapper from "../../components/FormCardWrapper";
import FormField from "../../components/FormField";

const AccessControlDeviceForm = ({ initialData, onSubmit, isLoading }) => {
  const [deviceData, setDeviceData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    setDeviceData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeviceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Validation function
  const validate = () => {
    let tempErrors = {};

    // Check if each required field has a value
    inputFields.forEach((field) => {
      if (field.required && !deviceData[field.name]) {
        tempErrors[field.name] = `${field.label} is required.`;
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data
    if (!validate()) {
      showSnackbar("Please fill out the required fields.", "error");
      return;
    }

    try {
      await onSubmit(deviceData);
      showSnackbar("Device saved successfully!", "success");
      setDeviceData(initialData); // Reset form data
    } catch (err) {
      console.error("Failed to save device:", err);
      showSnackbar(
        "Failed to save device: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  return (
    <div className="container mx-auto">
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <DeviceBasicInfo
          inputFields={inputFields}
          deviceData={deviceData}
          handleChange={handleChange}
          errors={errors}
        />
        <div className="col-span-1 md:col-span-2 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white btn max-w-lg w-full"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Device"}
          </button>
        </div>
      </form>
      <SnackbarComponent />
    </div>
  );
};

export default AccessControlDeviceForm;

const DeviceBasicInfo = ({ inputFields, deviceData, handleChange, errors }) => (
  <FormCardWrapper title="Device Information">
    <div className="grid grid-cols-1  gap-4">
      {inputFields.map((field, index) => (
        <FormField
          key={index}
          field={field}
          value={deviceData[field.name] ?? ""}
          onChange={handleChange}
          error={errors[field.name]}
        />
      ))}
    </div>
  </FormCardWrapper>
);

const inputFields = [
  {
    label: "Device Name",
    name: "deviceName",
    type: "text",
    required: true,
  },
  {
    label: "Door Name",
    name: "door",
    type: "text",
    required: true,
  },
  {
    label: "Serial Number",
    name: "serialNumber",
    type: "text",
    required: true,
  },
];
