import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import DistrictDropdown from "../../components/DistrictDropdown";
import { useRegisterMutation } from "../../redux/authApiSlice";
import useSnackbar from "../../hooks/useSnackbar";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    password: "",
    address: "",
    district: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};

    // Phone number validation (must be exactly 10 digits)
    if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number must be exactly 10 digits.";
    }

    // Password validation (must be at least 8 characters)
    if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      showSnackbar("Please correct the errors in the form.", "error");
      return;
    }

    try {
      await register(formData).unwrap();
      showSnackbar("Registration successful!", "success");
      navigate("/profile"); // Navigate to profile or home page upon successful registration
    } catch (error) {
      showSnackbar(
        error.data?.message || "Failed to register due to an unknown error.",
        "error"
      );
      console.error("Registration error:", error);
    }
  };

  return (
    <div className=" flex min-h-screen items-center justify-center bg-gradient-to-r from-blue-50 to-gray-50">
      <SnackbarComponent />
      <div className=" w-full max-w-md bg-white shadow-lg rounded-3xl overflow-hidden p-8">
        <div className="flex flex-col items-center">
          <FaUserPlus className="text-6xl text-indigo-600 mb-4" />
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Sign Up</h2>
          <p className="text-gray-600 text-center mb-8">
            Create your account to join our community.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative">
            <label htmlFor="name" className="sr-only">
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="phone" className="sr-only">
              Phone
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && (
              <div className="text-red-500 text-sm">{errors.phone}</div>
            )}
          </div>
          <div className="relative">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <div className="text-red-500 text-sm ">{errors.password}</div>
            )}
          </div>
          <div className="relative">
            <label htmlFor="address" className="sr-only">
              Address
            </label>
            <input
              id="address"
              name="address"
              type="text"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="relative">
            <label htmlFor="district" className="sr-only">
              District
            </label>
            <DistrictDropdown
              name="district"
              value={formData.district}
              onChange={handleChange}
              required={true}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <button
            type="submit"
            className={`w-full py-3 mt-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-300 ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "Registering..." : "Sign Up"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
