import { useState } from "react";
import { useSelector } from "react-redux";

import { selectGym } from "../../redux/gymSlice";
import { FaPlus, FaMicrochip, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FetchWrapper from "../../components/FetchWrapper";
import ConfirmDialog from "../../components/ConfirmDialog"; 
import useSnackbar from "../../hooks/useSnackbar"; 
import { useDeleteAccessControlDeviceMutation, useGetDevicesByGymWithPaginationQuery } from "../../redux/accessDeviceApiSlice";

const DeviceScreen = () => {
  const { showSnackbar, SnackbarComponent } = useSnackbar(); 
  const { gymId } = useSelector(selectGym); 
  const navigate = useNavigate();
  const [deleteDevice] = useDeleteAccessControlDeviceMutation(); 
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const {
    data: devices,
    isLoading,
    isError,
    error,
  } = useGetDevicesByGymWithPaginationQuery({
    gymId,
    page: 0,
    size: 25,
  });

  // Handle navigation to the create device screen
  const handleAddDevice = () => {
    navigate("add"); // Adjust this route as necessary
  };

  // Handle edit device
  const handleEditDevice = (deviceId) => {
    navigate(`edit/${deviceId}`); // Navigate to the edit page
  };

  const handleCardClick = (deviceId) => {
    navigate(`${deviceId}`); // Navigate to the device detail page if needed
  };

  // Handle delete device
  const handleDeleteDevice = async () => {
    try {
      await deleteDevice(selectedDeviceId).unwrap();
      console.log(`Device with ID ${selectedDeviceId} deleted`);
      showSnackbar("Device deleted successfully", "success"); // Show success snackbar
    } catch (error) {
      console.error("Failed to delete device:", error);
      showSnackbar(error.data?.message); // Show error snackbar
    } finally {
      setDialogOpen(false); // Close the confirmation dialog after deleting
    }
  };

  // Open confirmation dialog
  const confirmDeleteDevice = (deviceId) => {
    setSelectedDeviceId(deviceId);
    setDialogOpen(true);
  };

  return (
    <div className="container mx-auto p-4 min-h-[90vh] flex flex-col items-center justify-center">
      <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
        {devices && devices.content.length > 0 ? (
          <div className="flex flex-col gap-6 items-center justify-center">
            {/* Display Devices */}
            <div className="flex flex-wrap justify-center gap-6 items-center">
              {devices.content.map((device) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onEdit={() => handleEditDevice(device.id)}
                  onDelete={() => confirmDeleteDevice(device.id)}
                  onClick={() => handleCardClick(device.id)}
                />
              ))}

              <AddDeviceButton onClick={handleAddDevice} text="Add New Device" />
            </div>
          </div>
        ) : (
          <AddDeviceButton onClick={handleAddDevice} text="Add Device" />
        )}
      </FetchWrapper>

      {/* Confirm Delete Dialog */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Delete Device"
        message="Are you sure you want to delete this device? This action cannot be undone."
        onConfirm={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          handleDeleteDevice();
        }}
        onCancel={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          setDialogOpen(false);
        }}
      />

      {/* Snackbar Component */}
      <SnackbarComponent />
    </div>
  );
};

// Mini Component for Device Card
const DeviceCard = ({ device, onEdit, onDelete, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="group card card-compact w-64 bg-base-100 shadow-xl relative cursor-pointer"
    >
      <figure>
        <div className="w-full h-48 bg-gray-300 flex items-center justify-center text-gray-500">
          <FaMicrochip className="text-6xl" />
        </div>
      </figure>
      <div className="card-body">
        <h3 className="card-title text-lg font-bold text-gray-800">
          {device.deviceName}
        </h3>
        <p className="text-gray-600">Door: {device.door}</p>
        <p className="text-gray-600">Serial Number: {device.serialNumber}</p>

        <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className="btn bg-blue-400 btn-square btn-sm text-gray-700 hover:text-primary"
          >
            <FaEdit />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className="btn bg-red-400 btn-square btn-sm text-gray-700 hover:text-red-500"
          >
            <FaTrash />
          </button>
        </div>
      </div>
    </div>
  );
};

// Mini Component for Add Device Button
const AddDeviceButton = ({ onClick, text }) => (
  <div className="flex flex-col items-center justify-center min-h-96">
    <button
      onClick={onClick}
      className="btn btn-square bg-sky-500 text-white hover:bg-sky-600 w-24 h-24 flex items-center justify-center"
    >
      <FaPlus className="text-4xl" />
    </button>
    <p className="mt-4">{text}</p>
  </div>
);

export default DeviceScreen;
