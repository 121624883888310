import React from "react";
import { useResetJudgeResultsMutation } from "../../redux/judgeApiSlice"; // Adjust the path as needed

const ResetJudgeResults = () => {
  const [resetJudgeResults, { isLoading }] = useResetJudgeResultsMutation();

  const handleReset = async () => {
    // Ask for user confirmation before resetting
    const confirmReset = window.confirm("Are you sure you want to reset the judge results?");
    if (!confirmReset) return;

    try {
      await resetJudgeResults().unwrap();
    } catch (error) {
      console.error("Failed to reset judge results:", error);
      alert("Failed to reset judge results. Please try again.");
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "10px" }}>
      <button
        onClick={handleReset}
        disabled={isLoading}
        style={{
          marginLeft: "auto",
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#007BFF",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        {isLoading ? "Resetting..." : "Reset Results"}
      </button>
    </div>
  );
};

export default ResetJudgeResults;
