import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import dayjs from "dayjs";
import Table from "../../../components/Table";

import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDeleteInvoiceMutation } from "../../../redux/clientInvoiceApiSlice";
import ConfirmDialog from "../../../components/ConfirmDialog";
import useSnackbar from "../../../hooks/useSnackbar";

// Self-contained ActionButtons component
const ActionButtons = ({ data }) => {
  const navigate = useNavigate();
  const { id: invoiceId } = data;
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  // Handle Edit action
  const handleEdit = () => {
    navigate(`invoices/${invoiceId}/edit`);
  };

  // Handle Delete action
  const handleDelete = async () => {
    try {
      await deleteInvoice(invoiceId).unwrap();
      showSnackbar("Invoice deleted successfully", "success");
    } catch (error) {
      showSnackbar(error.data.message || "Failed to delete invoice", "error");
      console.error("Failed to delete invoice:", error);
    }
    setIsDialogOpen(false);
  };

  // Open the confirm dialog for deletion
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  // Close the dialog box
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        {/* Edit Button */}
        <button
          onClick={handleEdit}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 hidden md:inline"
        >
          Edit
        </button>
        {/* Delete Button */}
        <button
          onClick={openDialog}
          className="bg-red-500 text-white p-2 rounded hover:bg-red-600 hidden md:inline"
        >
          Delete
        </button>
        {/* Mobile Edit Button */}
        <button
          onClick={handleEdit}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 md:hidden"
        >
          <FaEdit />
        </button>
        {/* Mobile Delete Button */}
        <button
          onClick={openDialog}
          className="bg-red-500 text-white p-2 rounded hover:bg-red-600 md:hidden"
        >
          <FaTrash />
        </button>
      </div>

      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Delete Invoice"
        message="Are you sure you want to delete this invoice? This action cannot be undone."
        onConfirm={handleDelete}
        onCancel={closeDialog}
      />

      {/* Snackbar Component */}
      <SnackbarComponent />
    </>
  );
};

// InvoicesTable component
const InvoicesTable = ({
  invoices,
  pageNo,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
  pagination,
}) => {
  const navigate = useNavigate();

  const headers = {
    values: [
      "Plan Name & Duration",
      "Total & Discounted Amount",
      "Amount Paid",
      "Amount Due",
    ],
  };

  // Format rows for the table
  const rows = invoices.content.map((invoice) => {
    const startDate = dayjs(invoice.membershipStartDate);
    const endDate = dayjs(invoice.membershipEndDate);
    const durationInMonths = endDate.diff(startDate, "month", true).toFixed(1);

    return {
      id: invoice.id,
      "Plan Name & Duration": (
        <div>
          <div>{invoice.pricingPlanDto.planName}</div>
          <div className="text-xs text-gray-500">{durationInMonths} months</div>
        </div>
      ),
      "Total & Discounted Amount": (
        <div>
          <div>Total: Rs {invoice.totalAmount}</div>
          <div>SP: Rs {invoice.discountedAmount}</div>
        </div>
      ),
      "Amount Paid": (
        <div className="badge badge-success  text-white font-semibold text-sm px-4 py-1 min-w-[100px] text-center">
          Rs {invoice.amountPaid}
        </div>
      ),
      "Amount Due":
        invoice.amountDue === 0 ? (
          <div className="badge badge-success text-white font-semibold text-sm px-4 py-1 min-w-[100px] text-center">
            <FaCheck />
            <span className="ml-2">Cleared</span>
          </div>
        ) : (
          <div className="badge badge-error font-semibold  text-white text-sm px-4 py-1 min-w-[100px] text-center">
            Rs {invoice.amountDue}
          </div>
        ),
    };
  });

  const handleRowClick = (invoice) => {
    navigate(`invoices/${invoice.id}`);
  };

  return (
    <div className="mt-10">
      <h2 className="text-2xl font-semibold mb-4">Invoices</h2>

      <Table
        headers={headers}
        rows={rows}
        onRowClick={handleRowClick}
        ActionsComponent={ActionButtons}
      />

      <div className="mt-4 flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <label htmlFor="pageSize" className="mr-2">
            Rows per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={onPageSizeChange}
            className="border rounded p-1"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="p-2 border rounded disabled:opacity-50"
            onClick={() => onPageChange(pageNo - 1)}
            disabled={pageNo === 0}
          >
            Previous
          </button>
          <span>
            Page {pageNo + 1} of {totalPages}
          </span>
          <button
            className="p-2 border rounded disabled:opacity-50"
            onClick={() => onPageChange(pageNo + 1)}
            disabled={pageNo >= totalPages - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicesTable;
