import { useSelector } from "react-redux";
import AccessControlDeviceForm from "./AccessControlDeviceForm"; // Ensure the correct path
import { selectGym } from "../../redux/gymSlice";
import { useCreateAccessControlDeviceMutation } from "../../redux/accessDeviceApiSlice";

const AddDeviceScreen = () => {
  const [createAccessControlDevice, { isLoading }] =
    useCreateAccessControlDeviceMutation();
  const { gymId } = useSelector(selectGym);



  const initialData = {
    gymId: gymId,
    deviceName: "k40 pro",
    door: "",
    serialNumber: "",
  };

  const handleSubmit = async (deviceDto) => {
    await createAccessControlDevice(deviceDto).unwrap();
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Device</h1>
      <AccessControlDeviceForm
        initialData={initialData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddDeviceScreen;
