import React from "react";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { useGetLast12MonthsAttendanceQuery } from "../../redux/attendanceApiSlice";
import FetchWrapper from "../../components/FetchWrapper";

const AttendanceBarChart = () => {
  const { clientId } = useParams(); // Get clientId from the URL
  const { data, error, isLoading } = useGetLast12MonthsAttendanceQuery(clientId);

  const labels = data ? Object.keys(data) : [];
  const attendanceData = data ? Object.values(data) : [];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Total Days Attended",
        data: attendanceData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="w-full h-[400px] max-w-[700px] mx-auto"> {/* Fixed height and width */}
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false, // Allows custom height/width
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Days Attended",
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Month",
                },
              },
            },
          }}
        />
      </div>
    </FetchWrapper>
  );
};

export default AttendanceBarChart;
