import React from 'react'
import AttendanceBarChart from './AttendanceBarChart'
import ClientAttendance from './ClientAttendance'

const AttendanceDashboard = () => {
  return (
    <div>
      <ClientAttendance/>
      <AttendanceBarChart/>
    </div>
  )
}

export default AttendanceDashboard
