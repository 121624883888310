import { apiSlice } from "./apiSlice";

export const judgeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Endpoint to set a result for a specific judge
    setJudgeResult: builder.mutation({
      query: ({ judgeId, result }) => ({
        url: `public/api/judge/setResult`,
        method: "POST",
        params: { judgeId, result }, // Send as query parameters
      }),
      invalidatesTags: ["JudgeResults"],
    }),

    // Endpoint to reset all judge results
    resetJudgeResults: builder.mutation({
      query: () => ({
        url: `public/api/judge/reset`,
        method: "POST",
      }),
      invalidatesTags: ["JudgeResults"],
    }),

    // Endpoint to get all judge results and the isStarted status
    getJudgeResults: builder.query({
      query: () => `public/api/judge/results`,
      providesTags: ["JudgeResults"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useSetJudgeResultMutation,
  useResetJudgeResultsMutation,
  useGetJudgeResultsQuery,
} = judgeApiSlice;
