const RouteConfig = {
  HOME: "/home",
  ABOUT: "/about",
  PRICING: "/pricing",
  CONTACT: "/contact-us",
  NYFC_GAME: "/nyfc-game",

  GYM_CMS :"/gym-management",

  GYM_CATEGORY: "/gyms",
  GYM_SELECTOR: "/gym-profiles",
  
  DEVICE: "/devices",

  CLIENTS: "/clients",
  SCHEDULE: "/Schedule",
  SMS: "/sms",

  PROFILE: "/profile",

  ATTENDANCE_SCREEN: "/attendance",

  ADMIN_HOME: "/admin-home",
  MANAGE_Gyms: "/manage-gyms",
  Manage_Users: "/manage-users",
  ADMIN_REPORTS: "/admin-reports",

  LOGIN: "/login",
  SIGNUP: "/signup",
  LOGOUT: "/logout",
};

export default RouteConfig;
