import React, { useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { useGetClientInOutRecordsForMonthQuery } from "../../redux/attendanceApiSlice";
import FetchWrapper from "../../components/FetchWrapper";

dayjs.extend(weekday);

const ClientAttendance = () => {
  const { clientId } = useParams();
  const currentMonth = dayjs().format("MM");
  const currentYear = dayjs().format("YYYY");
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const {
    data: attendanceRecords = [],
    isError,
    error,
    isLoading,
  } = useGetClientInOutRecordsForMonthQuery({
    clientId,
    month: selectedMonth,
    year: selectedYear,
  });

  const groupByDay = (records) => {
    return records.reduce((acc, record) => {
      const day = dayjs(record.datetime).format("DD");
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(record);
      return acc;
    }, {});
  };

  const groupedRecords = groupByDay(attendanceRecords);

  const daysInMonth = dayjs(`${selectedYear}-${selectedMonth}`).daysInMonth();
  const firstDayOfMonth = dayjs(
    `${selectedYear}-${selectedMonth}-01`
  ).weekday();
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Weekday short names (Sun, Mon, etc.)
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <div className="p-4">
      {/* Sticky Header for Month/Year Selection */}
      <div className="sticky top-0 bg-white z-10 py-2 shadow-sm flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <h1 className="text-xl md:text-2xl font-bold">Client Attendance</h1>
        <div className="flex space-x-2 md:space-x-4">
          <div className="form-control w-32 md:w-48">
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="select select-bordered w-full"
            >
              {Array.from({ length: 12 }, (_, i) => {
                const monthValue = dayjs().month(i).format("MM");
                const monthName = dayjs().month(i).format("MMMM");
                return (
                  <option key={i} value={monthValue}>
                    {monthName}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-control w-32 md:w-48">
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="select select-bordered w-full"
            >
              {Array.from({ length: 5 }, (_, i) => {
                const yearValue = currentYear - i;
                return (
                  <option key={i} value={yearValue}>
                    {yearValue}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <FetchWrapper
        isError={isError}
        error={error}
        isLoading={isLoading}
        isEmptyList={attendanceRecords.length === 0}
        emptyListInfo="attendance"
      >
        {/* Calendar grid */}
        <div className="grid grid-cols-7 gap-2">
          {/* Weekday Headers */}
          {weekdays.map((weekday) => (
            <div key={weekday} className="text-center font-bold text-sm">
              {weekday}
            </div>
          ))}

          {/* Empty grid items before the first day of the month */}
          {Array.from({ length: firstDayOfMonth }, (_, i) => (
            <div key={`empty-${i}`} className="h-12"></div>
          ))}

          {/* Days of the month */}
          {daysArray.map((day) => {
            const dayStr = day.toString().padStart(2, "0");
            const recordsForDay = groupedRecords[dayStr] || [];
            const isPresent = recordsForDay.length > 0;

            const tooltipContent = isPresent
              ? recordsForDay
                  .map(
                    (record) =>
                      `${record.status}: ${dayjs(record.datetime).format("h:mm A")}`
                  )
                  .join(", ")
              : "Absent";

            return (
              <div
                key={day}
                className={`border h-12 flex items-center justify-center rounded-md ${
                  isPresent
                    ? "bg-green-100 tooltip tooltip-bottom"
                    : "bg-red-100"
                }`}
                data-tip={tooltipContent} // DaisyUI's way of attaching tooltips
              >
                <span className="font-bold">{day}</span>
              </div>
            );
          })}
        </div>
      </FetchWrapper>
    </div>
  );
};

export default ClientAttendance;
