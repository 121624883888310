import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientByIdQuery } from "../../redux/clientApiSlice";

import FetchWrapper from "../../components/FetchWrapper";
import dayjs from "dayjs";
import {
  FaPhone,
  FaEnvelope,
  FaHome,
  FaBirthdayCake,
  FaMoneyCheck,
  FaEdit,
  FaPlusCircle,
  FaFingerprint,
  FaLock,
  FaCreditCard,
  FaCalendarAlt,
  FaUser,
  FaCheckCircle,
  FaTimesCircle,
  FaCrown,
  FaPlus,
  FaPowerOff,
  FaTrash,
} from "react-icons/fa";
import InvoicesTable from "./invoice/InvoicesTable";
import { useGetInvoicesByClientIdQuery } from "../../redux/clientInvoiceApiSlice";
import {
  useAddClientsToDeviceMutation,
  useAddRemoteCardMutation,
  useAddRemoteFingerPrintMutation,
  useGetDevicesByClientIdQuery,
  useGetDevicesByGymWithPaginationQuery,
  useRemoveClientsFromDeviceMutation,
} from "../../redux/accessDeviceApiSlice";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";
import useSnackbar from "../../hooks/useSnackbar";
import RouteConfig from "../../config/RouteConfig";

const ClientProfile = () => {
  const { clientId } = useParams();
 
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser,
  } = useGetClientByIdQuery(clientId);

  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
    error: errorInvoices,
  } = useGetInvoicesByClientIdQuery({
    clientId,
    sortBy: "createdAt",
    sortOrder: "desc",
    pageNo,
    pageSize,
  });

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageNo(0); // Reset to the first page
  };

  return (
    <div className="container mx-auto mb-5 md:px-2">
      <FetchWrapper
        isLoading={isLoadingUser}
        isError={isErrorUser}
        error={errorUser}
      >
        {user && <UserProfile user={user} />}
        <ActionButtons />
      </FetchWrapper>

      <FetchWrapper
        isLoading={isLoadingInvoices}
        isError={isErrorInvoices}
        error={errorInvoices}
        isEmptyList={!isLoadingInvoices && invoices?.content.length === 0}
        emptyListInfo={"invoice"}
      >
        {invoices && invoices.content.length > 0 && (
          <InvoicesTable
            clientId={clientId}
            invoices={invoices}
            pageNo={pageNo}
            pageSize={pageSize}
            totalPages={invoices.paginationResponse.totalPages}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        )}
      </FetchWrapper>
    </div>
  );
};

const UserProfile = ({ user }) => {
  const { gymId } = useSelector(selectGym);
  const navigate = useNavigate();
  

  const calculateDaysLeft = (endDate) => {
    const today = dayjs();
    const expirationDate = dayjs(endDate);
    return expirationDate.diff(today, "day");
  };

  const daysLeft = user.membershipEndDate
    ? calculateDaysLeft(user.membershipEndDate)
    : null;
  const daysLeftText =
    daysLeft >= 0
      ? `${daysLeft} days left`
      : `${Math.abs(daysLeft)} days expired`;
  const daysLeftColor = daysLeft >= 0 ? "text-green-600" : "text-red-600";

  return (
    <div className="card bg-base-100 shadow-md p-6 rounded-lg flex flex-col lg:flex-row gap-6">
      {/* Profile Image */}
      <div className="flex-shrink-0 flex flex-col items-center">
        <div className="relative w-32 h-32 lg:w-48 lg:h-48">
          {user.imageUrl ? (
            <img
              className="w-full h-full object-cover rounded-lg border-4 border-transparent bg-gradient-to-r from-gray-300 via-white to-gray-300 p-1 transform transition-transform duration-300 hover:scale-110"
              src={user.imageUrl}
              alt={user.name || "N/A"}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center rounded-lg border-4 border-transparent bg-gradient-to-r from-gray-300 via-white to-gray-300 p-1">
              <span className="text-4xl text-gray-700 font-semibold">
                {user.name ? user.name.charAt(0).toUpperCase() : "?"}
              </span>
            </div>
          )}
        </div>
        <p className="mt-2 text-lg font-semibold">{user.name || "N/A"}</p>
        <p className={`font-medium ${daysLeftColor}`}>{daysLeftText}</p>
        <button
          className="mt-2 btn btn-outline"
          onClick={() => navigate(`${RouteConfig.ATTENDANCE_SCREEN}/${user.id}`)}
        >
          Attendance
        </button>
      </div>

      {/* Information Sections */}
      <div className="flex-1 space-y-4">
        {/* Personal Information */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-lg font-bold flex items-center mb-2">
            Personal Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p>
              <FaEnvelope className="inline-block mr-2 text-red-600" /> Email:{" "}
              {user.email || "N/A"}
            </p>
            <p>
              <FaPhone className="inline-block mr-2 text-green-600" /> Phone:{" "}
              {user.phone || "N/A"}
            </p>
            <p>
              <FaHome className="inline-block mr-2 text-yellow-600" /> Address:{" "}
              {user.address || "N/A"}
            </p>
            <p>
              <FaBirthdayCake className="inline-block mr-2 text-pink-600" />{" "}
              Date of Birth: {user.dateOfBirth || "N/A"}
            </p>
          </div>
        </div>

        {/* Membership Details */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-lg font-bold flex items-center mb-2">
            Membership Details
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p>
              <FaCalendarAlt className="inline-block mr-2 text-purple-600" />{" "}
              Joined Date: {user.joinedDate || "N/A"}
            </p>
            <p>
              <FaMoneyCheck className="inline-block mr-2 text-teal-600" />{" "}
              Membership End Date: {user.membershipEndDate || "N/A"}
            </p>
          </div>
        </div>

        {/* Device Information */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-lg font-bold flex items-center mb-2">
            Device Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p>
              <FaCreditCard className="inline-block mr-2 text-indigo-600" />{" "}
              Card Number: {user.clientInfoInDevice?.card || "N/A"}
            </p>
            <p>
              {user.clientInfoInDevice?.privilege === 0 ? (
                <>
                  <FaUser className="inline-block mr-2 text-blue-600" />
                  Privilege: Normal User
                </>
              ) : user.clientInfoInDevice?.privilege === 14 ? (
                <>
                  <FaCrown className="inline-block mr-2 text-yellow-600" />
                  Privilege: Super Admin
                </>
              ) : (
                <>
                  <FaTimesCircle className="inline-block mr-2 text-red-600" />
                  Privilege: Unknown
                </>
              )}
            </p>

            <p>
              <FaLock className="inline-block mr-2 text-gray-600" /> Password:{" "}
              {user.clientInfoInDevice?.password || "N/A"}
            </p>
            <p>
              <FaFingerprint className="inline-block mr-2 text-indigo-600" />{" "}
              Fingerprint Count:{" "}
              {user.clientInfoInDevice?.fingerprints.length || "0"}
            </p>
          </div>
        </div>

        <DeviceList gymId={gymId} clientId={user.id} />
      </div>
    </div>
  );
};

const ActionButtons = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-8 flex items-center justify-center flex-col md:flex-row">
      <button
        className="bg-purple-500 text-white w-64 py-2 rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-400 mb-4 md:mb-0 md:mr-4"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`edit`);
        }}
      >
        <FaEdit className="inline mr-2" />
        Edit Client
      </button>
      <button
        className="bg-teal-500 text-white w-64 py-2 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 "
        onClick={(e) => {
          e.stopPropagation();
          navigate(`add-invoices`);
        }}
      >
        <FaPlusCircle className="inline mr-2" />
        Create Invoice
      </button>
    </div>
  );
};

const DeviceList = ({ gymId, clientId }) => {
  // Fetch devices of the gym and those associated with the client
  const {
    data: gymDevicesResponse,
    error: gymError,
    isLoading: gymLoading,
  } = useGetDevicesByGymWithPaginationQuery({ gymId });

  const {
    data: clientDevicesResponse,
    error: clientError,
    isLoading: clientLoading,
  } = useGetDevicesByClientIdQuery(clientId);

  const [addClientsToDevice] = useAddClientsToDeviceMutation();
  const [removeClientsFromDevice] = useRemoveClientsFromDeviceMutation();

  const gymDevices = gymDevicesResponse?.content || [];
  const clientDevices = clientDevicesResponse || [];
  const isEmptyList = gymDevices.length === 0;

  // Function to handle adding a client to a device
  const handleAddClientToDevice = async (deviceId) => {
    await addClientsToDevice({ deviceId, clientIds: [clientId] });
  };

  // Function to handle removing a client from a device
  const handleRemoveClientFromDevice = async (deviceId) => {
    await removeClientsFromDevice({ deviceId, clientIds: [clientId] });
  };

  return (
    <FetchWrapper
      isError={!!gymError || !!clientError}
      error={gymError || clientError}
      isLoading={gymLoading || clientLoading}
      isEmptyList={isEmptyList}
      emptyListInfo="devices"
    >
      <div className="bg-gray-100 p-4 rounded-lg">
        <h2 className="text-lg font-bold flex items-center mb-2">
          Device List
        </h2>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          {gymDevices.map((device) => {
            const isClientAdded = clientDevices.some(
              (clientDevice) => clientDevice.id === device.id
            );

            return (
              <div
                key={device.id}
                className={`p-4 rounded-lg border relative transition-all duration-300 ${
                  isClientAdded ? "bg-green-100" : "bg-red-100"
                }`}
              >
                <h3 className="text-lg font-bold mb-2">
                  {device.deviceName || "Unnamed Device"}
                </h3>
                <p>
                  <FaLock className="inline-block mr-2 text-gray-600" />
                  Door: {device.door || "Unknown"}
                </p>
                <p>
                  <FaFingerprint className="inline-block mr-2 text-indigo-600" />
                  Fingerprint Count: {device.fingers || "0"}
                </p>
                <div className="flex flex-col space-y-2  sm:flex-row sm:space-x-2 sm:space-y-0  mt-4">
                  {/* Conditionally show Add/Remove Client button */}
                  {isClientAdded ? (
                    <button
                      onClick={() => handleRemoveClientFromDevice(device.id)}
                      className="text-sm px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      <FaTrash className="inline-block mr-2" />
                      Remove Client
                    </button>
                  ) : (
                    <button
                      onClick={() => handleAddClientToDevice(device.id)}
                      className="text-sm px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      <FaPlus className="inline-block mr-2" />
                      Add Client
                    </button>
                  )}

                  <RemoteAccessAction device={device} clientId={clientId} />
                </div>
                {/* Active/Inactive State Indicator */}
                <div className="absolute top-2 right-2">
                  {isClientAdded ? (
                    <FaCheckCircle className="text-green-500" title="Active" />
                  ) : (
                    <FaPowerOff className="text-red-500" title="Inactive" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </FetchWrapper>
  );
};

const RemoteAccessAction = ({ device, clientId }) => {
  // Use the mutation hooks
  const [addRemoteFingerprint, { isLoading: isLoadingFingerprint }] =
    useAddRemoteFingerPrintMutation();
  const [addRemoteCard, { isLoading: isLoadingCard }] =
    useAddRemoteCardMutation();

  // Use the snackbar hook
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  // Handle the Add Fingerprint button click
  const handleAddFingerprint = async () => {
    try {
      await addRemoteFingerprint({ deviceId: device.id, clientId }).unwrap();
      showSnackbar("Fingerprint added successfully", "success");
    } catch (error) {
      showSnackbar("Failed to add fingerprint", "error");
      console.error("Failed to add fingerprint:", error);
    }
  };

  // Handle the Add Card button click
  const handleAddCard = async () => {
    try {
      await addRemoteCard({ deviceId: device.id, clientId }).unwrap();
      showSnackbar("Card added successfully", "success");
    } catch (error) {
      showSnackbar("Failed to add card", "error");
      console.error("Failed to add card:", error);
    }
  };

  return (
    <>
      {/* <button
        onClick={handleAddCard}
        className="text-sm px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        disabled={isLoadingCard} // Disable the button while loading
      >
        {isLoadingCard ? (
          "Adding..."
        ) : (
          <>
            <FaIdCard className="inline-block mr-2" /> Add Card
          </>
        )}
      </button> */}

      <button
        onClick={handleAddFingerprint}
        className="text-sm px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        disabled={isLoadingFingerprint} // Disable the button while loading
      >
        {isLoadingFingerprint ? (
          "Adding..."
        ) : (
          <>
            <FaFingerprint className="inline-block mr-2" /> Add Fingerprint
          </>
        )}
      </button>

      {/* Snackbar Component */}
      <SnackbarComponent />
    </>
  );
};

export default ClientProfile;
